<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        {{
          inputs.language === 'en'
            ? 'Consider the molecule shown below:'
            : 'Considérez la molécule montrée ci-dessous :'
        }}
      </p>

      <p class="pl-8 mb-6">
        <v-img style="max-width: 115px" :src="imageName" />
      </p>

      <p class="mb-n3">
        {{
          inputs.language === 'en'
            ? 'a) What is the VSEPR notation for the molecule?'
            : 'a) Quelle est la notation VSEPR pour la molécule ?'
        }}
      </p>

      <v-radio-group v-model="inputs.vsepr" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3 mt-2">
        {{
          inputs.language === 'en'
            ? 'b) What is the molecular geometry of the molecule?'
            : 'b) Quelle est la géométrie moléculaire pour la molécule ?'
        }}
      </p>

      <v-radio-group v-model="inputs.geometry" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? options2 : options2Fr"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Question491',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        vsepr: null,
        geometry: null,
      },
      options1: [
        {text: '$\\ce{AB4}$', value: 'AB4'},
        {text: '$\\ce{AB3E}$', value: 'AB3E'},
        {text: '$\\ce{AB2E2}$', value: 'AB2E2'},
        {text: '$\\ce{ABE3}$', value: 'ABE3'},
        {text: '$\\ce{AB2E}$', value: 'AB2E'},
        {text: '$\\ce{AB2}$', value: 'AB2'},
        {text: '$\\ce{AE2}$', value: 'AE2'},
      ],
      options2: [
        {text: 'Tetrahedral', value: 'tetrahedral'},
        {text: 'Bent', value: 'bent'},
        {text: 'Trigonal Planar', value: 'trigonalPlanar'},
        {text: 'Linear', value: 'linear'},
        {text: 'Trigonal Pyramidal', value: 'trigonalPyramidal'},
      ],
      options2Fr: [
        {text: 'Tétraédrique', value: 'tetrahedral'},
        {text: 'Coudée', value: 'bent'},
        {text: 'Plan trigonal', value: 'trigonalPlanar'},
        {text: 'Linéaire', value: 'linear'},
        {text: 'Pyramidal trigonal', value: 'trigonalPyramidal'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/sulfurDichloride_LS.png';
    },
  },
};
</script>
<style scoped></style>
